$(function () {

    /*//////////////////////////////////////////////////////////////////////
    /////////////////////////// ANIMATION JS ///////////////////////////////
    //////////////////////////////////////////////////////////////////////*/
    var $animation_elements = $('.animation-element');
    var $window = $(window);

   /*//////////////////////////////////////////////////////////////////////
    /////////////////// ANIMATING MENU NAVIGATION DISPLAY //////////////////
    //////////////////////////////////////////////////////////////////////*/
    $("button.menu").click(function(){
        $("#overlaysidemenu").fadeIn(500);
        $("div.sidemenu").fadeIn(500).addClass("open");
    });

    $("#overlaysidemenu").click(function(){
        $("div.sidemenu").removeClass("open").fadeOut(400);
        $("#overlaysidemenu").fadeOut(400);
    });
});